// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-edit-page-js": () => import("./../../../src/pages/edit-page.js" /* webpackChunkName: "component---src-pages-edit-page-js" */),
  "component---src-pages-missing-info-js": () => import("./../../../src/pages/missing-info.js" /* webpackChunkName: "component---src-pages-missing-info-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-report-issue-js": () => import("./../../../src/pages/report-issue.js" /* webpackChunkName: "component---src-pages-report-issue-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-templates-course-content-template-js": () => import("./../../../src/templates/CourseContentTemplate.js" /* webpackChunkName: "component---src-templates-course-content-template-js" */),
  "component---src-templates-course-part-overview-template-js": () => import("./../../../src/templates/CoursePartOverviewTemplate.js" /* webpackChunkName: "component---src-templates-course-part-overview-template-js" */),
  "component---src-templates-info-page-template-js": () => import("./../../../src/templates/InfoPageTemplate.js" /* webpackChunkName: "component---src-templates-info-page-template-js" */)
}

